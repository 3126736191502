import React, { useState, useEffect } from 'react';
import StatusTracker from './StatusTracker';
import FeedbackForm from './FeedbackForm'; // Import the FeedbackForm component
import ImageGallery from './ImageGallery';
import './RegistrationForm.css';
import './VideoUpload.css';

function VideoUpload({ token, loggedInUser }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadFolder, setUploadFolder] = useState(null);
  const [selection, setSelection] = useState('CID');
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(null); 
  const [showApprovalButtons, setShowApprovalButtons] = useState(true); 
  const [showFeedbackForm, setShowFeedbackForm] = useState(false); 
  const [downloadURL, setDownloadURL] = useState(null); // New state for download URL
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [fileName, setFileName] = useState('Keine Datei ausgewählt'); // Standardstatus
  const [isButtonScanDisabled, setIsButtonScanDisabled] = useState(false);
  const [isButtonDownloadDisabled, setIsButtonDownloadDisabled] = useState(true);
  const [isButtonUploadDisabled, setIsButtonUploadDisabled] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);
  const [galleryContent, setGalleryContent] = useState([]);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const fetchGalleryContent = async (folder) => {
        try {
            const response = await fetch(`https://v3s-api-backend.3d-medico.com/downloadFolderContent?folder=${folder}`);
            const data = await response.json();
            setGalleryContent(data.content); // Store the content in state
            setGalleryVisible(true);
        } catch (error) {
            console.error("Fehler beim Abrufen der Galerie-Inhalte:", error);
        }
    };
    if ((galleryVisible) && uploadFolder) {
      fetchGalleryContent(uploadFolder);
    }
  }, [galleryVisible, uploadFolder]);

  const handleStatusChange = (status) => {
    if (status === "Warten auf Freigabe!") {
      setGalleryVisible(true); // Set gallery visible when status matches
    }
    else {setGalleryVisible(false);}
  };

  //const handleStatusChange = (status) => {
  //  if (status === "Warten auf Freigabe!") {
  //    fetchGalleryContent(uploadFolder);
  //  }
  //};

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const newFile = event.target.files[0];
      setFileName(newFile.name); // Setzt den Dateinamen nach Auswahl
      setSelectedFile(newFile);
      // Determine if the video is portrait or landscape
      const videoURL = URL.createObjectURL(newFile);
      const video = document.createElement('video');
      video.src = videoURL;
      video.onloadedmetadata = () => {
        const aspectRatio = video.videoWidth / video.videoHeight;
        setIsPortrait(aspectRatio < 1); // True if portrait, false if landscape
        URL.revokeObjectURL(videoURL); // Clean up object URL
      };
    } else {
      setFileName('Keine Datei ausgewählt'); // Zurücksetzen, wenn keine Datei ausgewählt ist
      setSelectedFile(null); // Setzt selectedFile zurück
      setIsPortrait(false);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Bitte eine Datei auswählen.');
      return;
    }

    setUploadStatus('Upload läuft...');
    setShowCancelButton(true);
    setIsButtonUploadDisabled(true);

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('selection', selection);
    formData.append('loggedInUser', loggedInUser);

    try {
      const response = await fetch('https://v3s-api-backend.3d-medico.com/upload', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload fehlgeschlagen');
      }

      const data = await response.json();
      setUploadStatus('Upload erfolgreich!');
      setUploadFolder(data.folder);

    } catch (error) {
      setUploadStatus('Fehler beim Upload: ' + error.message);
    }
  };

  const handleCancelScan = () => {
    const confirmCancel = window.confirm('Möchten Sie den Scan wirklich abbrechen?');
    if (confirmCancel) {
      setSelectedFile(null); 
      setUploadStatus(null); 
      setUploadFolder(null); 
      setShowApprovalButtons(true); 
      setShowFeedbackForm(false);
      setApprovalStatus(null);
      setSelection('CID');
      setShowCancelButton(true);
      setDownloadURL(null);
      setIsButtonScanDisabled(false);
      setIsButtonDownloadDisabled(true);
      setFileName('Keine Datei ausgewählt');
      setIsButtonUploadDisabled(false);
      setGalleryVisible(false);
      setIsComplete(false);
    }
  };

  const checkModelFilesPeriodically = async () => {
    // Status auf "3D-Modell wird zusammengestellt" setzen
    setApprovalStatus('3D-Modell wird zusammengestellt...');

  
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`https://v3s-api-backend.3d-medico.com/checkModelFiles?folder=${uploadFolder}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const data = await response.json();
  
        if (data.status === 'ready') {
          // Modell ist fertig, zeige den Download-Button an und stoppe die Abfragen
          setApprovalStatus('3D-Scan steht zum Download bereit!');
          setIsButtonDownloadDisabled(false);
          setDownloadURL(`https://v3s-api-backend.3d-medico.com/downloadAttempt?folder=${uploadFolder}`);
          clearInterval(intervalId); // Stoppe die wiederholte Abfrage
        } else {
          setApprovalStatus('Modell wird noch verarbeitet...');
        }
  
      } catch (error) {
        setApprovalStatus('Fehler beim Überprüfen des Modells: ' + error.message);
        clearInterval(intervalId); // Stoppe die Abfrage bei einem Fehler
      }
    }, 10000); // Alle 10 Sekunden die API abfragen
  };


  const handleApprovalDecision = async (decision) => {
    const confirmApproval = window.confirm(`Möchten Sie den Scan wirklich ${decision === 'accept' ? 'akzeptieren' : 'ablehnen'}?`);
    setIsButtonScanDisabled(true);
    if (!confirmApproval) {
      return;
    }
    const tagId = decision === 'accept' ? 2 : 3;

    try {
      const response = await fetch('https://v3s-api-backend.3d-medico.com/approval', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ folder: uploadFolder, tagId }),
      });

      if (response.ok) {
        setGalleryVisible(false);
        setIsComplete(true);
        if (decision === 'accept') {
          setApprovalStatus('Scan akzeptiert!');
          checkModelFilesPeriodically();
          setShowApprovalButtons(false);
          setShowCancelButton(false);
          setDownloadURL(`https://v3s-api-backend.3d-medico.com/downloadAttempt?folder=${uploadFolder}`);
        } else {
          setShowFeedbackForm(true);
          setShowCancelButton(true);
        }
      } else {
        throw new Error('Fehler beim Setzen des Tags');
      }
    } catch (error) {
      alert('Fehler beim Setzen des Tags: ' + error.message);
    }
  };

  const handleNewScan = () => {
    setShowFeedbackForm(false);
    setSelectedFile(null);
    setUploadStatus(null);
    setUploadFolder(null);
    setShowApprovalButtons(true);
    setApprovalStatus(null);
    setDownloadURL(null);
    setShowCancelButton(true);
    setSelection('CID');
    setIsButtonScanDisabled(false);
    setIsButtonDownloadDisabled(true);
    setFileName('Keine Datei ausgewählt');
    setIsButtonUploadDisabled(false);
    setGalleryVisible(false);
    setIsComplete(false);
  };

  return (
    <div>
      {uploadStatus !== 'Upload erfolgreich!' && (
        <div>
          <h2>Video hochladen</h2>
          <label>Wähle die Kategorie und Dein Video:</label>
          <p><select value={selection} onChange={(e) => setSelection(e.target.value)}>
            {/* Kategorien */}
            <option value="CID">Standard</option>
            <option value="CIDface">Gesicht</option>
            <option value="CIDhand">Hand</option>
            <option value="CIDarm">Arm</option>
            <option value="CIDtorso">Torso</option>
            <option value="CIDleg">Bein(e)</option>
            <option value="CIDfoot">Fuß</option>
            <option value="CID1rot">1x360°</option>
            <option value="CID2rot">2x360°</option>
            <option value="CID3rot">3x360°</option>
          </select>

          <label htmlFor="file-upload" className="custom-file-upload">
            Video auswählen
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            accept="video/*"
            style={{ display: 'none' }} // Versteckt das Standard-Input-Feld
          />
          {fileName} {/* Standardstatus oder Dateiname anzeigen */}
          <button disabled={isButtonUploadDisabled} onClick={handleUpload}>Hochladen</button></p>
          {uploadStatus && <p>Status: {uploadStatus}</p>}
          {uploadStatus && uploadStatus !== 'Upload erfolgreich!' && <span className="spinner"></span>}
        </div>
      )}

      {uploadFolder && (
        <div>
          <p>Dein Video wurde als "{uploadFolder}" hochgeladen.</p>

          {/* Display cancel button above the gallery */}
          {uploadStatus === 'Upload erfolgreich!' && showCancelButton && (
            <button onClick={handleCancelScan}>Scan Abbrechen</button>
          )}

          {/* Display approval buttons */}
          {galleryVisible && showApprovalButtons && !showFeedbackForm && (
            <div>
              <h4>Möchtest Du den Scan akzeptieren?</h4>
              <button disabled={isButtonScanDisabled} onClick={() => handleApprovalDecision('accept')}>
                Ja, herunterladen
              </button>
              <button disabled={isButtonScanDisabled} onClick={() => handleApprovalDecision('reject')}>
                Nein, ablehnen
              </button>
            </div>
          )}

          {/* Display approval status */}
          {approvalStatus && (
            <div>
              <h4>{approvalStatus}</h4>
              {approvalStatus !=='3D-Scan steht zum Download bereit!' && (
                <span className="spinner"></span>
              )}
            </div>
          )}

          {/* Download section */}
          {downloadURL && (
            <div>
              <a href={downloadURL} target="_blank" rel="noopener noreferrer">
                <button disabled={isButtonDownloadDisabled}>3D-Modell herunterladen</button>
              </a>
              <button onClick={handleNewScan}>Neuen Scan starten</button>
            </div>
          )}

          {showFeedbackForm && (
            <FeedbackForm
              folder={uploadFolder}
              loggedInUser={loggedInUser}
              onNewScan={handleNewScan}
            />
          )}
        </div>
      )}

      {uploadFolder && !isComplete && (
        //<StatusTracker folder={uploadFolder} onStatusChange={handleStatusChange} isComplete={isComplete} />
        <StatusTracker folder={uploadFolder} isComplete={isComplete} onStatusChange={handleStatusChange} />
      )}

      {uploadFolder && galleryVisible && !isComplete && (
        <ImageGallery images={galleryContent} folder={uploadFolder} />
      )}

      {selectedFile && !galleryVisible && uploadStatus !== 'Upload erfolgreich!' && (
        <div>
          <h3 className="video-preview-title">Video Vorschau:</h3>
          <div className={`video-preview ${isPortrait ? 'portrait' : 'landscape'}`}>
            <video key={selectedFile.name} controls>
              <source src={URL.createObjectURL(selectedFile)} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </div>
  );


}

export default VideoUpload;
