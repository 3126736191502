import React, { useState } from 'react';
import './ImageGallery.css';  // Stilierung der Galerie

function ImageGallery({ images, folder }) {
  const [selectedImage, setSelectedImage] = useState(null);  // Für die Vollbildansicht

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeFullscreen = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <h2>Bildergalerie (Vorschau)</h2>
      <div className="gallery-grid">
        {images && images.length > 0 ? (
          images.map((image, index) => (
            <img
              key={index}
              src={`https://v3s-api-backend.3d-medico.com/getPreviewImage?folder=${folder}&image=${image.name}`}
              alt={`Vorschau ${index + 1}`}
              onClick={() => handleImageClick(image)}
              className="gallery-image"
            />
          ))
        ) : (
          <p>Keine Bilder vorhanden</p>
        )}
      </div>

      {selectedImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img
            src={`https://v3s-api-backend.3d-medico.com/getPreviewImage?folder=${folder}&image=${selectedImage.name}`}
            alt="Vollbildvorschau"
            className="fullscreen-image"
          />
        </div>
      )}
    </div>
  );
}

export default ImageGallery;
