import React, { useState, useEffect } from 'react';
import './App.css';
import VideoUpload from './components/VideoUpload';
import Login from './components/Login';
import RegistrationForm from './components/RegistrationForm';
import logo from './assets/3D-Medico Logo mit Text groß.png';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (token && loggedInUser) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [token, loggedInUser]);

  const handleLogin = (userToken, username) => {
    setToken(userToken);
    setLoggedInUser(username);
    localStorage.setItem('token', userToken);
  };

  const handleLogout = () => {
    setToken(null);
    setLoggedInUser(null);
    localStorage.removeItem('token');
  };

  const openRegistrationForm = () => {
    setIsRegisterOpen(true);
  };

  const closeRegistrationForm = () => {
    setIsRegisterOpen(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-text-container">
          <img src={logo} alt="Logo" className="logo" />
          <h4>3D-Medico | Denn nichts kann uns aufhalten!</h4>
        </div>
        
        <h1>Video-3D-Scan Pilot-App</h1>
      </header> 
      <p>Benutze nicht "Neu Laden" bzw. verlasse die Seite nicht während des 3D-Scans!!! Du wirst sonst autom. ausgeloggt und musst wieder neu starten.</p>  
      <hr />
      
      {!token || !loggedInUser ? (
        <div>
          <div>
            <Login onLogin={handleLogin} />
            <button onClick={openRegistrationForm}>Registrieren</button>
          </div>
          <div className="intro-video">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/e81-oiWZXrA?si=qwBXwowWf332DBT0&amp;controls=0&amp;" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerPolicy="strict-origin-when-cross-origin" 
            allowFullScreen>
          </iframe>
          </div>
        </div>
      ) : (
        <div>
          <p>Willkommen, {loggedInUser}!</p>
          <div className="button-group">
            <button onClick={handleLogout}>Logout</button>
          </div>
          <VideoUpload token={token} loggedInUser={loggedInUser} />
        </div>
      )}

      {isRegisterOpen && <RegistrationForm onClose={closeRegistrationForm} />}
      
      <footer className="footer">
        <div className="footer-column">
          <h4>Postanschrift</h4>
          <p>3D-Medico<br />Teutonenstraße 74<br />12524 Berlin</p>
        </div>
        
        <div className="footer-column">
          <h4>Arbeitsplatz</h4>
          <p>c/o ST3AM Adlershof<br />Rudower Chaussee 28/1. OG<br />12489 Berlin<br />vorherige Terminvereinbarung gewünscht</p>
          <h4>Kontakt</h4>
          <p>E-Mail: info[.a.t.]3d-medico.de<br />Tel: +49 (0)152 3665 2844</p>
        </div>

        <div className="footer-column">
          <h4>Links</h4>
          <a href="https://3d-medico.de/kontakt.html" target="_blank" rel="noopener noreferrer">Kontakt</a><br />
          <a href="https://3d-medico.de/impressum.html" target="_blank" rel="noopener noreferrer">Impressum</a><br />
          <a href="https://3d-medico.de/3d-scan.html" target="_blank" rel="noopener noreferrer">Video 3D-Scan Info</a>
          <h4>Dein Feedback zählt!</h4>
          <p>Hast Du Ideen, Anregungen oder bist auf Fehler gestoßen? Lass es uns wissen!</p>
        </div>
      </footer>

      <div className="footer-bottom">
        <hr />
        <p>&copy; Copyright 2024 Think3DDD - Alle Rechte vorbehalten</p>
      </div>






    </div>
  );
}

export default App;
