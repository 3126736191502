import React, { useState } from 'react';
import './RegistrationForm.css';

function RegistrationForm({ onClose }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    consent: false,
    areas: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      const areas = checked
        ? [...prevData.areas, value]
        : prevData.areas.filter((area) => area !== value);
      return { ...prevData, areas };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://v3s-api-backend.3d-medico.com/register-mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Deine Registrierungsanfrage wurde erfolgreich erfasst! Du erhältst nach einer kurzen Prüfung Deine Zugangsdaten.');
        onClose();
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Deine Registrierungsanfrage konnte leider nicht gesendet werden! Wende Dich bitte an uns direkt per Mail.');
      });
  };

  return (
    <div className="registration-form">
      <button onClick={onClose} className="close-btn">X</button>
      <h2>Werde Jetzt Teil unserer Pilotphase!</h2>
      <form onSubmit={handleSubmit}>
        <input name="firstName" placeholder="Vorname*" value={formData.firstName} onChange={handleChange} required />
        <input name="lastName" placeholder="Nachname*" value={formData.lastName} onChange={handleChange} required />
        <input name="company" placeholder="Firma*" value={formData.company} onChange={handleChange} required />
        <input name="email" placeholder="Email*" value={formData.email} onChange={handleChange} required />
        <input name="phone" placeholder="Telefon" value={formData.phone} onChange={handleChange} />
        
        <label>
          <input type="checkbox" name="consent" checked={formData.consent} onChange={handleChange} required />
          Ich stimme der Verarbeitung meiner Daten zu*
        </label>
        
        <p>Anwendungsbereiche*</p>
        <label><input type="checkbox" value="Gesicht" onChange={handleCheckboxChange} /> Gesicht</label>
        <label><input type="checkbox" value="Arm" onChange={handleCheckboxChange} /> Arm</label>
        <label><input type="checkbox" value="Hand" onChange={handleCheckboxChange} /> Hand</label>
        <label><input type="checkbox" value="Torso" onChange={handleCheckboxChange} /> Torso</label>
        <label><input type="checkbox" value="Bein" onChange={handleCheckboxChange} /> Bein</label>
        <label><input type="checkbox" value="Fuss" onChange={handleCheckboxChange} /> Fuss</label>
        
        <button type="submit">Ich registriere mich für die Pilotphase</button>
      </form>
    </div>
  );
}

export default RegistrationForm;
