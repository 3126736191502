// StatusTracker.js
import React, { useState, useEffect } from 'react';
import './StatusTracker.css';

//function StatusTracker({ folder, onStatusChange, isComplete }) {
function StatusTracker({ folder, isComplete, onStatusChange }) {
  const [status, setStatus] = useState('Status wird abgerufen...');
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isComplete) {
      setIsReady(true);
      return;
    }

    const fetchStatus = async () => {
      try {
        const response = await fetch(`https://v3s-api-backend.3d-medico.com/status?folder=${folder}`);
        const data = await response.json();
        setStatus(data.status);

        // Trigger onStatusChange callback when status is "Warten auf Freigabe!"
        if (data.status === "Warten auf Freigabe!" || data.status === "Abgeschlossen!" || data.status === "Freigabe abgelehnt!") {
          clearInterval(intervalId);
          setIsReady(true);
          onStatusChange && onStatusChange(data.status); // Call the callback
        }
      } catch (error) {
        setStatus('Fehler beim Abrufen des Status');
      }
    };

    const intervalId = setInterval(fetchStatus, 10000);
    fetchStatus();

    return () => clearInterval(intervalId);
  }, [folder, isComplete, onStatusChange]);

  return (
    <div>
      {!isReady && <h3>Status des Video-3D-Scans:</h3>}
      {!isReady && <p>{status}</p>} 
      {!isReady && <span className="spinner"></span>}
    </div>
  );
}


export default StatusTracker;
