import React, { useState } from 'react';

function FeedbackForm({ folder, loggedInUser, onNewScan }) {
  const [feedbackReason, setFeedbackReason] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [isButtonFeedbackDisabled, setIsButtonFeedbackDisabled] = useState(false);

  const handleSubmit = async () => {
    if (!feedbackReason) {
      alert('Bitte wähle einen Grund aus.');
      return;
    }
    setIsButtonFeedbackDisabled(true)
    // Debugging: Prüfe die übergebenen Werte
    // console.log('Folder:', folder);
    // console.log('User:', loggedInUser);

    try {
      // Send feedback to the backend API
      const response = await fetch('https://v3s-api-backend.3d-medico.com/sendFeedback', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          folder,            // Use folder passed from VideoUpload
          feedbackReason,    // Feedback reason selected by the user
          feedbackText,      // Additional feedback text
          user: loggedInUser // Use loggedInUser passed from VideoUpload
        }),
      });

      if (response.ok) {
        alert('Vielen Dank für Dein Feedback!');
        onNewScan(); // Reset or start a new scan after feedback is sent
      } else {
        alert('Feedbeck wurde nicht abgesendet!');
      }
      setIsButtonFeedbackDisabled(false)
    } catch (error) {
      alert('Fehler beim Absenden des Feedbacks: ' + error.message);
    }
  };

  return (
    <div>
      <h3>Feedback zur Ablehnung Deines Scans</h3>
      <select value={feedbackReason} onChange={(e) => setFeedbackReason(e.target.value)}>
        <option value="">Grund bitte auswählen</option>
        <option value="Nur ein Test">Nur ein Test</option>
        <option value="Bildergalerie fehlerhaft">Bildergalerie fehlerhaft</option>
        <option value="Körperteil nicht erkennbar">Körperteil nicht erkennbar</option>
        <option value="Modellqualität unzureichend">Modellqualität unzureichend</option>
        <option value="Anderes">Anderes</option>
      </select>
      <br />
      <textarea 
        value={feedbackText}
        onChange={(e) => setFeedbackText(e.target.value)}
        placeholder="Gib hier bitte zusätzliche Kommentare ein"
      />
      <br />
      <button disabled={isButtonFeedbackDisabled} onClick={handleSubmit}>Feedback absenden</button>
    </div>
  );
}

export default FeedbackForm;
